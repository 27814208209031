import React, { Component } from "react";
import Page from "../../components/Page";

class Landbouw extends Component {
  render() {
    return (
      <Page title="Landbouw">
        <p>
          <img
            alt="Landbouw"
            src={process.env.PUBLIC_URL + "/images/themas/landbouw.jpg"}
            className="fullwidth shadow"
          />
        </p>

        <p>
          De landbouwbedrijven in de gemeente zijn een belangrijke economische
          factor. Als gemeente hebben we de taak om hen te ondersteunen. De
          landbouwraad is onze vinger aan de pols om het gemeentelijke beleid
          bij te sturen.
        </p>
        <p>
          We ondersteunen en promoten onze lokale landbouw via verschillende
          acties zoals campagnes tegen zwerfvuil, de aankoop van een camera
          tegen sluikstorten, een premie voor het inzaaien van van gewassen en
          premie voor het maaien van perceelsranden. We ondersteunen de korte
          keten werking via het ondersteunen van het WK hoeveijs likken.
        </p>
        <p>
          2017 werd getroffen door een extreme droogte. De schattingscommissie
          levert voortreffelijk werk om onze landbouwers bij te staan.
        </p>
        <p>
          Onze landbouwers zijn de bevoorrechte gebruikers van het
          beverhoutsveld. Met CD&amp;V verhinderen we de verkoop van het
          beverhoutsveld. Een nieuw reglement bied meer zekerheid voor onze
          landbouwers, dit reglement moet echter nog verfijnt worden zodat de
          gronden effectief toegewezen worden aan landbouwers.
        </p>

        {/* <p>
          De landbouwbedrijven in de gemeente zijn een belangrijke economische
          factor. Als gemeente hebben we de taak om hen te ondersteunen. De
          landbouwraad is onze vinger aan de pols om het gemeentelijke beleid
          bij te sturen.
        </p>
        <p>
          We ondersteunen en promoten onze lokale landbouw via verschillende
          acties zoals campagnes tegen zwerfvuil, de aankoop van een camera
          tegen sluikstorten, de gemeente beschikt over een premie voor het
          inzaaien van vanggewassen en het maaien van perceelsranden. We
          ondersteunen de korte keten werking via het ondersteunen van het WK
          hoeveijs likken.
        </p>
        <p>
          2017 werd getroffen door een extreme droogte. De schattingscommissie
          levert voortreffelijk werk om onze landbouwers bij te staan.
        </p>
        <p>
          Onze landbouwers zijn de bevoorrechte gebruikers van het
          beverhoutsveld. Met CD&amp;V verhinderen we de verkoop van het
          beverhoutsveld. Een nieuw reglement bied meer zekerheid voor onze
          landbouwers, dit reglement moet echter nog verfijnt worden zodat de
          gronden effectief toegewezen worden aan landbouwers.
        </p> */}
      </Page>
    );
  }
}

export default Landbouw;
