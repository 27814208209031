import React, { Component } from "react";
import { css } from "glamor";

class GridContainer extends Component {
  render() {
    let containerCss = css({
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between"
    });

    return <div className={containerCss}>{this.props.children}</div>;
  }
}

export default GridContainer;
