import React, { Component } from "react";
import HeaderLink from "./HeaderLink";
import { css } from "glamor";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { tocIsHidden: true };
    this.toggleToc = this.toggleToc.bind(this);
  }
  toggleToc() {
    const { tocIsHidden } = this.state;
    this.setState({
      tocIsHidden: !tocIsHidden
    });
  }

  render() {
    let headerCss = css(
      {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "80px",
        backgroundColor: "#00413a",
        zIndex: 1200,
        cursor: "pointer",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)"
      },
      {
        "@media(max-width: 640px)": {
          height: "44px",
          backgroundSize: "50px auto"
        }
      }
    );

    let headerH1Css = css(
      {
        position: "absolute",
        top: 0,
        left: "20px",
        height: "80px",
        lineHeight: "80px",
        margin: 0,
        padding: 0,
        fontSize: "2em",
        color: "white",
        fontWeight: "bold"
      },
      {
        "@media(max-width: 640px)": {
          lineHeight: "44px",
          left: "20px",
          fontSize: "1.2em"
        }
      }
    );

    let iconTocCss = css(
      {
        position: "absolute",
        top: "0",
        right: "10px",
        margin: "0px",
        padding: "0px",
        paddingRight: "10px",
        cursor: "pointer",
        backgroundImage: this.state.tocIsHidden
          ? "url(" + process.env.PUBLIC_URL + "/images/icon_toc.png)"
          : "url(" + process.env.PUBLIC_URL + "/images/icon_close.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "44px 44px",
        height: "80px",
        width: "44px",
        display: "none",
        zIndex: 1111
      },
      {
        "@media(max-width: 900px)": {
          display: "inline"
        }
      },
      {
        "@media(max-width: 640px)": {
          height: "44px",
          width: "44px",
          paddingRight: 0
        }
      }
    );

    let headerUlCss = css(
      {
        position: "absolute",
        top: 0,
        right: 0,
        margin: 0,
        padding: 0,
        paddingRight: "10px",
        overflow: "hidden"
      },
      {
        "@media(max-width: 900px)": {
          display: this.state.tocIsHidden ? "none" : "inline",
          position: "fixed",
          width: "100vw",
          height: "100vh",
          left: 0,
          top: "80px",
          paddingTop: "0px",
          zIndex: 999,
          backgroundColor: "#00413a",
          overflow: "auto",
          borderTop: "1px solid #ccc"
        }
      },
      {
        "@media(max-width: 640px)": {
          top: "44px",
          paddingTop: "0px"
        }
      }
    );

    return (
      <header className={headerCss}>
        <h1 className={headerH1Css}>
          <a href="/">{this.props.title}</a>
        </h1>
        <div className={iconTocCss} onClick={this.toggleToc} />
        <ul className={headerUlCss}>
          <HeaderLink action={this.toggleToc} exact url="/" label="Home" />
          <HeaderLink action={this.toggleToc} url="/nieuws" label="Nieuws" />
          <HeaderLink
            action={this.toggleToc}
            url="/bevoegdheden"
            label="Bevoegdheden"
          />
          <HeaderLink
            action={this.toggleToc}
            url="/wie-ben-ik"
            label="Wie ben ik?"
          />
          <HeaderLink action={this.toggleToc} url="/links" label="Links" />
          <HeaderLink action={this.toggleToc} url="/contact" label="Contact" />
        </ul>
      </header>
    );
  }
}

export default Header;
