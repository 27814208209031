import React, { Component } from "react";
import Page from "../../components/Page";

class IntegraalWaterbeleid extends Component {
  render() {
    return (
      <Page title="Integraal Waterbeleid">
        <p>
          <img
            alt="Integraal Waterbeleid"
            src={
              process.env.PUBLIC_URL +
              "/images/themas/integraal_waterbeleid.jpg"
            }
            className="fullwidth shadow"
          />
        </p>
        <p>
          Om tot een geïntegreerd waterbeleid te komen is de belangrijkste taak
          het wegnemen van de vervuiling. Het afvalwater afkomstig van de kernen
          van Beernem, Oedelem en Sint-Joris wordt al jaren lang gezuiverd. In
          het buitengebied is echter nog heel wat werk aan de winkel.
        </p>
        <p>
          In deze legislatuur werd dan ook nog nooit zoveel geïnvesteerd in de
          sanering van het afvalwater. Van volgende straten en omgevingen zal
          het afvalwater niet meer lozen in beken en waterlopen, maar gezuiverd
          worden door de aanleg van nieuwe riolering: Bruggestraat,
          Sijselestraat en omgeving, Vullaertstraat, Ruweschuurstraat –
          Hoogstraat, Oostveld - Pluime en Zeldonk.
        </p>
        <p>
          Uniek voor Vlaanderen is dat de gemeente de volledig kosten voor het
          scheiden van afvalwater en regenwater op het privaat terrein volledig
          voor haar rekening neemt.
        </p>
      </Page>
    );
  }
}

export default IntegraalWaterbeleid;
