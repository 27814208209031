import React, { Component } from "react";
import Page from "../../components/Page";

class Omgevingsvergunningen extends Component {
  render() {
    return (
      <Page title="Omgevingsvergunningen">
        <p>
          <img
            alt="Omgevingsvergunningen"
            src={
              process.env.PUBLIC_URL +
              "/images/themas/omgevingsvergunningen.jpg"
            }
            className="fullwidth shadow"
          />
        </p>
        <p>Vergunningsaanvragen moeten correct en vlot verlopen.</p>
      </Page>
    );
  }
}

export default Omgevingsvergunningen;
