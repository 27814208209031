import React, { Component } from "react";
import Page from "../../components/Page";

class OpenbareWerken extends Component {
  render() {
    return (
      <Page title="Openbare Werken">
        <p>
          <img
            alt="Openbare Werken"
            src={process.env.PUBLIC_URL + "/images/themas/openbare_werken.jpg"}
            className="fullwidth shadow"
          />
        </p>
        <p>
          Bij openbare werken staan communicatie in inspraak centraal. Bewoners
          moeten inspraak krijgen bij de herinrichting van hun straat of plein.
          Via een communicatietraject moeten inwoners mee de projectdefinitie
          kunnen bepalen en inspraak krijgen omtrent het ontwerp binnen de
          krijtlijnen die de gemeente of hogere overheid bepaald. Verschillende
          scenario’s moeten tegenover elkaar afgewogen worden.
        </p>
        <p>
          Onze wegen moeten goed onderhouden worden met aandacht voor de noden
          van de recreanten (rustzones) de bewoners (goede rijweg) en
          landbouwers (uitwijkstroken). Heraanleg van wegen moeten we afstemmen
          op het bomenbeheer en de aanleg van rioleringen.
        </p>
      </Page>
    );
  }
}

export default OpenbareWerken;
