import React, { Component } from "react";
import SocialIconLink from "./SocialIconLink";
import { css } from "glamor";

class SocialIconsContainer extends Component {
  render() {
    let socialIconsContainerCss = css(
      {
        paddingTop: "10px",
        float: "right"
      },
      {
        "@media(max-width: 640px)": {
          float: "none"
        }
      }
    );

    return (
      <div className={socialIconsContainerCss}>
        <nobr>
          <SocialIconLink url="mailto:claudio@claudiosaelens.be" />
          <SocialIconLink url="https://www.facebook.com/claudiosaelenscdenv/" />
          <SocialIconLink url="http://www.twitter.com/claudiosaelens" />
          <SocialIconLink url="http://www.linkedin.com/pub/claudio-saelens/53/998/618" />
          <SocialIconLink url="https://www.instagram.com/claudio_saelens/" />
        </nobr>
      </div>
    );
  }
}

export default SocialIconsContainer;
