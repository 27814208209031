import React, { Component } from "react";
import { css } from "glamor";

class Card extends Component {
  render() {
    let cardCss = css(
      {
        flex: "0 1 auto",
        width: "calc(33% - 1em)",
        marginBottom: "1em",
        visibility: this.props.hidden ? "hidden" : ""
      },
      {
        "@media(max-width: 900px)": {
          flex: "0 1 auto",
          width: "calc(50% - 1em)"
        }
      },
      {
        "@media(max-width: 640px)": {
          flex: "0 1 auto",
          width: "100%"
        }
      }
    );

    let aCss = css({
      width: "100%",
      paddingTop: "66%",
      position: "relative",
      display: "inline-block",
      overflow: "hidden",
      boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
      backgroundImage:
        "url(" + process.env.PUBLIC_URL + "/images/" + this.props.imgSrc + ")",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundColor: "white"
    });

    // let cardImageCss = css({
    //   maxWidth: "100%",
    //   position: "absolute",
    //   left: 0,
    //   top: 0,
    //   bottom: 0,
    //   right: 0,
    //   backgroundColor: "white"
    // });

    let cardHeaderCss = css({
      fontSize: "1em",
      fontWeight: "bold",
      margin: "0px",
      padding: "4px"
    });

    let cardDateCss = css({
      fontSize: "0.75em",
      color: "#333",
      padding: "0px 4px",
      textTransform: "uppercase"
    });

    let cardContentCss = css({
      padding: "4px"
    });

    return (
      <article className={cardCss}>
        <a
          href={this.props.titleLink}
          target={this.props.target}
          className={aCss}
        >
          {/* <img
            src={process.env.PUBLIC_URL + "/images/" + this.props.imgSrc}
            alt={this.props.title}
            className={cardImageCss}
          /> */}
        </a>
        <h1 className={`${cardHeaderCss} ${this.props.titleClass}`}>
          <a href={this.props.titleLink} target={this.props.target}>
            {this.props.title}
          </a>
        </h1>
        <div className={cardDateCss}>{this.props.date}</div>
        <div className={cardContentCss}>{this.props.children}</div>
      </article>
    );
  }
}

export default Card;
