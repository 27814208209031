import React, { Component } from "react";
import Page from "../components/Page";

class WhoAmI extends Component {
  render() {
    return (
      <Page title="Wie ben ik?">
        <p>
          <img
            alt="Claudio, Eveline, Lowie en Artuur"
            src={process.env.PUBLIC_URL + "/images/gezinsfoto.jpg"}
            className="fullwidth shadow"
          />
        </p>
        <h2>Wie is Claudio?</h2>
        <p>
          Ik zag op 15 maart 1979 het levenslicht in het Sint-Lucas ziekenhuis
          in Brugge, ging naar de lagere school in Sint-Joris en daarna naar het
          Sint-Gerolf Instituut (nu Emmaüs Aalter). Na mijn studies industrieel
          Ingenieur (KaHO Sint-Lieven) volgende ik nog een aanvullende opleiding
          Ruimtelijke planning (Ugent).
        </p>
        <p>
          Samen met Eveline Serry wonen wij in de Maria-Aaltersteenweg in
          Sint-Joris en hebben we twee zoontjes Lowie (8) en Artuur (4).
          Professioneel ben ik afdelingshoofd grondgebiedzaken (ruimtelijke
          ordening, technische dienst, openbare werken, huisvesting, milieu,
          preventie en evenementen, lokale economie) bij het gemeentebestuur
          Oostrozebeke.
        </p>
        <p>
          Daarnaast ben ik lid van de schoolraad Vrije Basisschool Sint-Joris en
          bestuurslid van de landelijke gilde Beernem.
        </p>
        <h2>Politieke Loopbaan</h2>
        <ul>
          <li>
            <p>
              1 januari 2009 – 31 december 2012
              <br />
              OCMW-raadslid Beernem
            </p>
          </li>
          <li>
            <p>
              1 januari 2013 – 31 december 2014
              <br />
              Gemeenteraadslid Beernem
            </p>
          </li>
          <li>
            <p>
              1 januari 2015 – 31 december 2018
              <br />
              Schepen van ruimtelijke ordening, wonen, landbouw, milieu en
              integraal waterbeleid in Beernem
            </p>
          </li>
          <li>
            <p>
              <b>Nieuw:</b> Sinds 1 januari 2019
              <br />
              Schepen van openbare werken, nutsvoorzieningen, integraal
              waterbeleid, grondbeleid, omgevingsvergunningen voor landbouw en
              bedrijven, landbouw en senioren
            </p>
          </li>
        </ul>
        <h2>Waar sta &amp; ga ik voor?</h2>
        <p>
          Zelf ben ik werkzaam binnen een gemeentebestuur. Dagdagelijks kom ik
          in contact met burgers en ken ik de gevoeligheden die binnen een
          gemeente leven.
        </p>
        <p>
          Deze ervaring wil ik inzetten voor de toekomst van alle inwoners van
          Beernem.
        </p>
        <ul>
          <li>
            <p>
              Het gezinsleven en een professionele loopbaan moeten op elkaar
              afgestemd kunnen worden. Kwalitatieve kinderopvang en een
              uitgebreide vakantiewerking zijn een must.
            </p>
          </li>
          <li>
            <p>
              Binnen een gemeente is het sociaal weefsel belangrijk. Binnen
              iedere woonkern is een goed uitgebouwde ontmoetingsruimte
              noodzakelijk.Beernem moet zich profileren als een gezonde, actieve
              en zorgzame gemeente. De verdere uitbouw van de
              sportinfrastructuur moet iedereen aan zetten tot bewegen. Zo
              creëren we een actieve en gezonde gemeente. De vele zorgcentra in
              de gemeente zijn een troef.
            </p>
          </li>
          <li>
            <p>
              Onze gemeente moet klaar zijn voor de toekomst. Duurzaamheid is
              het sleutelwoord. We moeten naar een zuiveringsgraad van 100% van
              ons afvalwater. Onze centra moeten groener worden, ruimte voor
              wonen en ondernemen is noodzakelijk, maar de locatiekeuze moet
              weloverwogen gebeuren.
            </p>
          </li>
          <li>
            <p>
              Onze gemeente heeft een uitgesproken landelijk karakter. Landbouw
              heeft hierin een belangrijke functie.
            </p>
          </li>
          <li>
            <p>
              Radicaal digitaal, onze inwoners moeten nog meer gebruik kunnen
              maken van de digitale weg zodat ze nog minder vaak tot op het
              gemeentehuis moeten komen. Beernem moet een CO<sub>2</sub>
              -neutrale gemeente worden, 20% CO<sub>2</sub> reductie tegen 2020
              en 40% tegen 2030.
            </p>
          </li>
        </ul>
      </Page>
    );
  }
}

export default WhoAmI;
