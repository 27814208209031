import React, { Component } from "react";
import ContactInfo from "./ContactInfo";
import AddressInfo from "./AddressInfo";
import SocialIconsContainer from "./SocialIconsContainer";
import { css } from "glamor";

class Footer extends Component {
  render() {
    let footerCss = css({
      backgroundColor: "#666",
      padding: "10px 0px",
      fontSize: "0.8em",
      lineHeight: "1.8em",
      color: "white",
      boxShadow: "0px -4px 8px 0px rgba(0, 0, 0, 0.25)"
    });

    let footerContainerCss = css(
      {
        maxWidth: "875px",
        marginLeft: "auto",
        marginRight: "auto"
      },
      {
        "@media(max-width: 900px)": {
          paddingLeft: "20px",
          paddingRight: "20px"
        }
      },
      {
        "@media(max-width: 640px)": {
          textAlign: "center"
        }
      }
    );

    let copyrightCss = css({
      textAlign: "center",
      fontSize: "0.75em"
    });

    let currentYear = new Date().getFullYear();

    return (
      <footer>
        <div className={footerCss}>
          <div className={footerContainerCss}>
            <SocialIconsContainer />
            <AddressInfo />
            <ContactInfo />
          </div>
        </div>
        <p className={copyrightCss}>
          &copy; Copyright {currentYear} Claudio Saelens<br />
        </p>
      </footer>
    );
  }
}

export default Footer;
