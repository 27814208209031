import React, { Component } from "react";
import Page from "../../components/Page";

class Grondbeleid extends Component {
  render() {
    return (
      <Page title="Grondbeleid">
        <p>
          <img
            alt="Grondbeleid"
            src={process.env.PUBLIC_URL + "/images/themas/grondbeleid.jpg"}
            className="fullwidth shadow"
          />
        </p>
        <p>
          Eigenaars moeten een rechtvaardige vergoeding krijgen voor gronden die
          onteigend worden.
        </p>
      </Page>
    );
  }
}

export default Grondbeleid;
