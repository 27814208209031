import React, { Component } from "react";
import GridContainer from "../components/GridContainer";
import Card from "../components/Card";

class WebLinksContainer extends Component {
  render() {
    return (
      <GridContainer>
        <Card
          title="Volg me op Facebook"
          titleLink="https://www.facebook.com/claudiosaelenscdenv/"
          imgSrc="like-fb.png"
          target="_blank"
        />
        <Card
          title="Gemeente Beernem"
          titleLink="https://www.beernem.be"
          imgSrc="beernem.png"
          target="_blank"
        />
        <Card
          title="Provincie West-Vlaanderen"
          titleLink="https://www.west-vlaanderen.be"
          imgSrc="west-vlaanderen.png"
          target="_blank"
        />
        <Card
          title="CD&V Beernem"
          titleLink="http://beernem.cdenv.be"
          imgSrc="cd-en-v-beernem.png"
          target="_blank"
        />
        <Card
          title="CD&V Nationaal"
          titleLink="http://www.cdenv.be"
          imgSrc="cd-en-v-nationaal.png"
          target="_blank"
        />
        <Card
          title="Beernem &quot;de weg vooruit&quot;"
          titleLink="http://beernem.cdenv.be/storage/local/beernem/beernem-versie-9.pdf"
          imgSrc="beernem-de-weg-vooruit.jpg"
          target="_blank"
        />
      </GridContainer>
    );
  }
}

export default WebLinksContainer;
