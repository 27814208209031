import React, { Component } from "react";
import Page from "../../components/Page";

class Nutsvoorzieningen extends Component {
  render() {
    return (
      <Page title="Nutsvoorzieningen">
        <p>
          <img
            alt="Nutsvoorzieningen"
            src={
              process.env.PUBLIC_URL + "/images/themas/nutsvoorzieningen.jpg"
            }
            className="fullwidth shadow"
          />
        </p>
        <p>
          Het doven van de openbare verlichting moet geëvalueerd worden:
          verkeersveiligheid en energiebesparing staan hierbij centraal. Verder
          moet er ingezet worden op nieuwe technologieën zoals warmtenetten,
          slimme verlichting, een uitrol van laadpalen voor elektrische
          voertuigen en slimme wegen (communicerende verkeerslichten en
          trajectcontrole).
        </p>
      </Page>
    );
  }
}

export default Nutsvoorzieningen;
