import React, { Component } from "react";
import Page from "../components/Page";
import GridContainer from "../components/GridContainer";
import Card from "../components/Card";
import WebLinksContainer from "../components/WebLinksContainer";
import NewsContainer from "../components/NewsContainer";
import ReactGA from "react-ga";
import { css } from "glamor";

class Home extends Component {
  constructor() {
    super();
    this.state = { newsItems: [] };
  }

  componentWillReceiveProps(props) {
    this.componentDidMount();
  }

  componentDidMount() {
    fetch(process.env.PUBLIC_URL + "/api/news.json")
      .then(response => {
        return response.json();
      })
      .then(newsItems => {
        this.setState({
          newsItems: newsItems
        });

        ReactGA.pageview("/");
      });
  }

  render() {
    let spacerCss = css({
      padding: "20px 0"
    });

    let mainContainerImage = css({
      position: "relative",
      width: "100%",
      paddingTop: "66%",
      backgroundImage:
        "url(" + process.env.PUBLIC_URL + "/images/claudio_liggend.jpg)",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundColor: "white",
      overflow: "hidden",
      boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)"
    });

    let mainContainerH1 = css(
      {
        padding: "10px",
        margin: 0
      },
      {
        "@media(max-width: 640px)": {
          fontSize: "1.2em"
        }
      }
    );

    let mainContainerH2 = css(
      {
        padding: "4px 10px",
        paddingTop: 0,
        paddingBottom: 10,
        margin: 0,
        fontSize: "1em"
      },
      {
        "@media(max-width: 640px)": {
          fontSize: "1em"
        }
      }
    );

    let mainContainerTitle = css({
      position: "absolute",
      left: 0,
      bottom: 0,
      backgroundColor: "rgba(255, 255, 255, 0.75)",
      width: "100%",
      margin: 0,
      height: "auto"
    });

    let mainContainerLogo = css(
      {
        position: "absolute",
        right: 20,
        bottom: 20,
        maxWidth: "90px"
      },
      {
        "@media(max-width: 640px)": {
          maxWidth: "50px"
        }
      }
    );

    return (
      <Page title="" shortTitle="Home">
        <div className={spacerCss}>
          <div className={mainContainerImage}>
            <div className={mainContainerTitle}>
              <h1 className={mainContainerH1}>Claudio Saelens</h1>
              <h2 className={mainContainerH2}>Schepen in Beernem</h2>
            </div>
            <img
              src={process.env.PUBLIC_URL + "/images/logo_cdenv.png"}
              className={mainContainerLogo}
              alt="CD&V"
            />
          </div>
        </div>
        <GridContainer>
          <Card
            title="Mijn bevoegdheden"
            titleLink="/bevoegdheden"
            imgSrc="themas/openbare_werken.jpg"
          >
            Openbare werken, nutsvoorzieningen, integraal waterbeleid,
            grondbeleid, omgevingsvergunningen voor landbouw en bedrijven,
            landbouw en senioren. De uitdagingen zijn groot, de kansen nog
            groter. Een gemeente met het oog op de toekomst, daar ga ik voor.
          </Card>
          <Card
            title="Wie ben ik?"
            titleLink="/wie-ben-ik"
            imgSrc="gezinsfoto.jpg"
          >
            Sommigen kennen mij als schepen of we ontmoetten elkaar bij één of
            andere gelegenheid in de gemeente. Voor anderen ben ik nog onbekend.
            Daarom wil ik mezelf en mijn gezin graag voorstellen.
          </Card>
          <Card
            title="Contacteer me"
            titleLink="/contact"
            imgSrc="claudio_koeien.jpg"
          >
            Ik hou ervan om in dialoog te gaan met mensen en ben dan ook
            benieuwd naar al jullie vragen, suggesties en bedenkingen.
            <br />
            <br />
            Aarzel niet om contact met mij op te nemen.
          </Card>
        </GridContainer>
        <h1>Nieuws</h1>
        <NewsContainer items={this.state.newsItems} limit={3} />
        <p className="center">
          <a href="/nieuws">meer nieuwsberichten</a>
        </p>
        <h1>Links</h1>
        <WebLinksContainer />
      </Page>
    );
  }
}

export default Home;
